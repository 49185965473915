import { FunctionComponent, useEffect, useState } from "react";
import styles from "./Header.module.css";
import Subscribe from "./Subscribe";
import NavMenu from "./NavMenu";

interface HeaderProps {
  aboutRef: React.RefObject<HTMLDivElement>;
  artistRef: React.RefObject<HTMLDivElement>;
  oracleRef: React.RefObject<HTMLDivElement>;
  aiRef: React.RefObject<HTMLDivElement>;
  FAQRef: React.RefObject<HTMLDivElement>;
}

const Header: FunctionComponent<HeaderProps> = ({ aboutRef, artistRef, oracleRef, aiRef, FAQRef }) => {

  const [isMobileView, setIsMobileView] = useState(false);

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    handleResize(); // Initial check on component mount

    window.addEventListener("resize", handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener on component unmount
    };
  }, []);

  return (
    <div className={styles.herosection}>
      <div className={styles.header}>
        <div className={styles.headerWrapper}>
          <div className={styles.logo}>
            <img
              className={styles.eetLogoAnimation1Icon}
              alt=""
              src="/output-onlinegiftools.gif"
            />
            <div className={styles.eet}>EET</div>
          </div>

          {/*

          <div className={styles.links}>
            <div className={styles.about} onClick={() => scrollToRef(aboutRef)}>About</div>
            <div className={styles.about} onClick={() => scrollToRef(aiRef)}>A.I.</div>
            <div className={styles.about} onClick={() => scrollToRef(artistRef)}>Artist</div>
            <div className={styles.about} onClick={() => scrollToRef(oracleRef)}>Oracle</div>
            <div className={styles.about} onClick={() => scrollToRef(FAQRef)}>FAQ</div>
          </div>

          */}
          <div className={styles.buttonwrapper}>
            <NavMenu isMobileView={isMobileView} connectButton={<a href="https://oracle.eet.et" target="_blank" className={styles.connectbutton}><div className={styles.secondaryCta}>CONNECT</div></a>} />
          </div>
        </div>
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.text}>
          <div className={styles.enterAConversationWithTheParent}>
            <div className={styles.establishedByCai}>
              Cai Guo-Qiang <span>×</span> KANON
            </div>
            <div className={styles.enterAConversationContainer}>
              <p className={styles.enterAConversation}>A CONVERSATION</p>
              <p className={styles.enterAConversation}>WITH THE UNSEEN WORLD</p>
            </div>
            <div className={styles.eetIntro}>
              EET is a novel oracle experience developed by internationally acclaimed artist Cai Guo-Qiang in collaboration with Kanon. With EET, you can have your fortune told on the blockchain with support from AI.
            </div>
          </div>
          <a href="https://oracle.eet.et" target="_blank" className={styles.buttonWrapper}>
            <div className={styles.askQuestion}>CONSULT ORACLE</div>
          </a>
        </div>
      </div>
      <div className={styles.learnMoreParent}>
        <div className={styles.consultOracle}>LEARN MORE</div>
        <img className={styles.frameChild} alt="" src="/vector-16.svg" />
      </div>
      <div className={styles.bottomGradient}></div>
    </div>
  );
};

export default Header;