import { FunctionComponent } from "react";
import styles from "./GifContainer.module.css";

const GifContainer: FunctionComponent = () => {
  return (
    <div className={styles.laddersection}>
      <div className={styles.contentleftParent}>
        <div className={styles.contentleft}>
          <div className={styles.headerContainer}>
            <p className={styles.aTrueMasterpiece}>THE FIRST-EVER GIFS GENERATED ON-CHAIN</p>
          </div>
          <div className={styles.eetFortunesAre}>
          Light weight, layered, and animated, the GIF has become the Internet’s own image format. GIFs brought visuals to the World Wide Web in the mid-1990s and then delivered the memes that helped drive Web 2.0, but on-chain images to date typically use easily scripted human-readable formats. EET continues the lineage by introducing the GIF to web3 with a custom encoder written in an Ethereum smart contract, bringing the complexity of image bytecode to the blockchain.
          </div>
          <a href="https://oracle.eet.et" target ="_blank" className={styles.getYourNftWrapper}>
            <div className={styles.getYourNft}>GET YOUR NFT</div>
          </a>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.fortuneAssemblyWrapper}>
            <video className={styles.fortuneAssembly} loop autoPlay playsInline muted>
            <source src="/fortune-assembly.webm" type="video/webm" />
            <source src="/fortune-assembly.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GifContainer;
