import { FunctionComponent } from "react";
import styles from "./FAQContainer.module.css";
import Accordion from "./Accordion";

const FAQContainer: FunctionComponent = () => {
  return (
    <div className={styles.faqsection}>
      <img
        className={styles.gradient}
        alt=""
        src="/svg-gradient-green.svg"
      />
      <div className={styles.faqinnersection}>
        <div className={styles.faq}>FAQ</div>
        <div className={styles.faqwrapper}>
          <Accordion />
        </div>
      </div>
      <img
        className={styles.reversed}
        alt=""
        src="/svg-gradient-green.svg"
      />
    </div>
  );
};

export default FAQContainer;
