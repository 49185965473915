import React from 'react';
import styles from './Galaxy.module.css';
import Tile from './Tile';

function getRandomImage() {
  const images = ["gua-1.png", "gua-2.png", "gua-3.png", "gua-4.png"];
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
}

const Galaxy: React.FC = () => {
  const tiles = Array.from({ length: Math.ceil((window.innerHeight * window.innerWidth) / (64 * 64)) }, (_, index) => (
    <Tile key={index} />
  ));

  return (
    <div className={styles.galaxyOuterWrapper}>
      <div className={styles.galaxyInnerWrapper}>
        <div className={styles.galaxyImgWrapper}></div>
          <img
            className={styles.purple}
            alt=""
            src={`/${getRandomImage()}`}
          />
        <div className={styles.galaxy}>{tiles}</div>
      </div>
    </div>
  );
};

export default Galaxy;