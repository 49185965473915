import { useEffect, useState } from "react";
import styles from "./NavMenu.module.css";

const NavMenu = ({ isMobileView, connectButton }: { isMobileView: boolean; connectButton: JSX.Element }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const renderMenuButton = () => (
    <div
      onClick={toggleMenu}
      className={`${styles.menuButton} ${isMenuOpen ? styles.menuButtonOpen : ""} transition duration-300 ml-2`}
    >
      <img src="/hamburger.svg" width={24} height={24} alt="hamburger menu icon" />
    </div>
  );

  return (
    <div className={styles.elementWrapper}>
      {!isMobileView && (
        <>
          <a
            href="https://oracle.eet.et"
            className={`${styles.navLink} ${styles.oracleLink}`}
          >
            ORACLE
          </a>
          <a
            href="https://oracle.eet.et/wallet"
            className={`${styles.navLink} ${styles.walletLink}`}
          >
            WALLET
          </a>
        </>
      )}
      {connectButton}
      {isMobileView && renderMenuButton()}
      {isMobileView && isMenuOpen && (
        <div className={`${styles.mobileMenu}`}>
          <a
            href="https://eet.et"
            className={`${styles.navLink} ${styles.homeLink}`}
          >
            HOME
          </a>
          <a
            href="https://oracle.eet.et"
            className={`${styles.navLink} ${styles.oracleLink}`}
          >
            ORACLE
          </a>
          <a
            href="https://oracle.eet.et/wallet"
            className={`${styles.navLink} ${styles.walletLink}`}
          >
            WALLET
          </a>
          <div className={`${styles.menuButtonContainer}`}>
            {renderMenuButton()}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavMenu;