import { FunctionComponent } from "react";
import styles from "./AIInterpretationContainer.module.css";

const AIInterpretationContainer: FunctionComponent = () => {
  return (
    <div className={styles.intelligencesection}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.shouldILongRiskAssetsWrapper}>
              <div className={styles.shouldILong}>
                Should I long risk assets?
              </div>
            </div>
            <div className={styles.shouldILongRiskAssetsContainer}>
              <div className={styles.shouldILong}>
                Should I long risk assets?
              </div>
            </div>
            <div className={styles.whatSchoolShouldIApplyToWrapper}>
              <div className={styles.whatSchoolShould}>
                What school should I apply to?
              </div>
            </div>
            <div className={styles.isItTimeToSellWrapper}>
              <div className={styles.isItTime}>Is it time to sell?</div>
            </div>
            <div className={styles.isItTimeToSellContainer}>
              <div className={styles.isItTime}>Is it time to sell?</div>
            </div>
            <div className={styles.isTheBottomInWrapper}>
              <div className={styles.isItTime}>Is the bottom in?</div>
            </div>
            <div className={styles.isTheBottomInContainer}>
              <div className={styles.isItTime}>Is the bottom in?</div>
            </div>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.shouldILongRiskAssetsFrame}>
              <div className={styles.shouldILong}>
                Should I long risk assets?
              </div>
            </div>
            <div className={styles.shouldILongRiskAssetsWrapper1}>
              <div className={styles.shouldILong}>
                Should I long risk assets?
              </div>
            </div>
            <div className={styles.whatSchoolShouldIApplyToContainer}>
              <div className={styles.whatSchoolShould}>
                What school should I apply to?
              </div>
            </div>
            <div className={styles.whatSchoolShouldIApplyToFrame}>
              <div className={styles.whatSchoolShould}>
                What school should I apply to?
              </div>
            </div>
            <div className={styles.isItTimeToSellFrame}>
              <div className={styles.isItTime}>Is it time to sell?</div>
            </div>
            <div className={styles.isItTimeToSellWrapper1}>
              <div className={styles.isItTime}>Is it time to sell?</div>
            </div>
            <div className={styles.isTheBottomInFrame}>
              <div className={styles.isItTime}>Is the bottom in?</div>
            </div>
            <div className={styles.isTheBottomInWrapper1}>
              <div className={styles.isItTime}>Is the bottom in?</div>
            </div>
          </div>
        </div>
        <img
          className={styles.shotsSo1Icon}
          alt=""
          src="/phone-mockups.png"
        />
      </div>
      <div className={styles.contentright}>
        <div className={styles.aFortuneInterpretatedContainer}>
          <p
            className={styles.aFortuneInterpretated}
          >{`MEANINGFUL FORTUNES 
          `}</p>
          <p className={styles.aFortuneInterpretated}>INTERPRETED BY A.I.</p>
        </div>
        <div className={styles.everyQuestionTo}>
        EET fortunes pack an I-Ching algorithm, Confucian wisdom, folklore, and contemporary crypto-meme culture into an exciting and meaningful NFT. You can chat directly with EET to decipher the results from the on-chain oracle, no need for a little white book or a human reader.
        </div>
        <a href="https://oracle.eet.et" target ="_blank" className={styles.askQuestionWrapper}>
          <div className={styles.askQuestion}>ASK QUESTION</div>
        </a>
      </div>
    </div>
  );
};

export default AIInterpretationContainer;
