import React, { useState } from "react";
import styles from "./Accordion.module.css";

const FAQSection: React.FC = () => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const questions = [
        "What is EET?",
        "How does the EET oracle work?",
        "How can I join the EET community?",
        "What kind of questions should I ask the EET oracle?",
        "Are questions submitted to the EET oracle private?",
        "What are the GUA token and the EET Fortune?",
        "Is each EET Fortune unique?",
        "How does the EET eDAO govern EET?",
        "Will there be any updates or extensions of EET after its launch?",
    ];

    const answers = [
        "EET is a AI-augmented fortune-telling experience on the blockchain, developed by internationally acclaimed artist Cai Guo-Qiang in collaboration with Kanon. It combines ancient wisdom, Eastern and Western philosophies, modern mathematics and contemporary crypto meme culture to deliver engaging and transformative fortunes on the Ethereum network.",
        "Users consult the EET oracle with a question, generate randomness, and then mint an EET Fortune. After minting, users can consult the EET AI for help in interpreting the fortune.",
        "You can follow the EET twitter account and subscribe to our mailing list to stay up to date with the latest developments.",
        "There are no fixed rules; however, oracles often work best with meaningful questions that are difficult to answer through reason and logic alone. Scroll up to the “Guidance for Navigating Life’s Toughest Questions” section for some examples.",
        "Yes. By default, the questions you submit to the EET oracle are private. Should you choose to, you can publish your question at any point after minting.",
        "The process of minting an EET Fortune starts with the minting of a GUA token. GUA tokens are 3x3 on-chain GIFs that visually represent your question, combined with randomness captured from your device at the time of minting, as well as randomness from the Ethereum blockchain. This GUA token is then staked (or transferred into) the EET smart contract. The EET smart contract then generates an EET fortune based on the information contained in the GUA token. The GUA token provides the background to the EET fortune image. This entire process happens in a single transaction. At the end of this process you will receive an EET Fortune in your Ethereum wallet.",
        "Fortunes come in 64 main path types with 4,096 variations. The backgrounds of EET Fortunes are based on color combinations of the underlying GUA token. Combining these sources of variation make it very unlikely that two EET Fortunes will ever be exactly the same.",
        "The EET eDAO is a gamified governance system developed by Kanon. This groundbreaking new governance mechanism rewards participants who contribute to EET’s growth through an on-chain game. Following EET’s debut, Cai and Kanon will pass control of EET (including the revenue it generates as well as control over pricing and supply) to the eDAO, which in turn will cycle this control among the latest winners in a continuous, competitive on-chain game.",
        "After the eDAO is launched and control of EET is handed over to the eDAO, Cai and Kanon will consider EET a complete piece of technology. The future development and direction of EET will then be determined by the eDAO and its participants.",
    ];

    const toggleAccordion = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className={styles.faqSection}>
            {questions.map((question, index) => (
                <div
                    key={index}
                    className={styles.accordion}
                    onClick={() => toggleAccordion(index)}
                >
                    <div className={styles.topAccSection}>
                        <span>{question}</span>
                        <span
                            className={`${styles.arrow} ${openIndex === index ? styles.rotated : ""
                                }`}
                        >
                            <img
                                className={styles.keyboardArrowDown}
                                alt=""
                                src="/keyboard-arrow-down.svg"
                            />
                        </span>
                    </div>
                    <div className={styles.bottomAccSection}>
                        {openIndex === index && <div className={styles.answers}>{answers[index]}</div>}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FAQSection;