import { FunctionComponent } from "react";
import styles from "./OracleContainer.module.css";

const OracleContainer: FunctionComponent = () => {
  return (
    <div className={styles.oraclesection}>
      <div className={styles.contentleft}>
        <img className={styles.separator} alt="" src="/separator-green.gif" />
        <div className={styles.aNovelOracleContainer}>
          <p className={styles.aNovelOracle}>A REVOLUTIONARY ORACLE WITH ANCIENT ROOTS</p>
        </div>
        <div className={styles.eetIsThe}>
        Aesthetically, EET provides a new visual language for those seeking guidance amid life’s uncertainties today. Behind the scenes, EET implements ancient wisdom through modern technology and mathematics.
        </div>
          <a href="https://oracle.eet.et" target ="_blank" className={styles.startSessionWrapper}>
            <div className={styles.startSession}>START SESSION</div>
          </a>
      </div>
      <div className={styles.showreelGradient}>
      <img className={styles.fortuneShowreel} alt="" src="/fortune-showreel.png" />
      </div>
    </div>
  );
};

export default OracleContainer;
