import { FunctionComponent, useEffect, useRef } from "react";
import Header from "../components/Header";
import OracleContainer from "../components/OracleContainer";
import AIInterpretationContainer from "../components/AIInterpretationContainer";
import CaiContainer from "../components/CaiContainer";
import GifContainer from "../components/GifContainer";
import QuestionContainer from "../components/QuestionContainer";
import FAQContainer from "../components/FAQContainer";
import styles from "./XL.module.css";
import Galaxy from "../components/Galaxy";
import CreatorSection from "../components/CreatorSection";

const XL: FunctionComponent = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const artistRef = useRef<HTMLDivElement>(null);
  const oracleRef = useRef<HTMLDivElement>(null);
  const aiRef = useRef<HTMLDivElement>(null);
  const FAQRef = useRef<HTMLDivElement>(null);

  // Add the setMobileHeight function here
  const setMobileHeight = () => {
    const windowHeight = window.innerHeight;
    document.documentElement.style.setProperty('--mobile-height', `${windowHeight}px`);
  };

  // Add useEffect to call setMobileHeight and add the event listener
  useEffect(() => {
    setMobileHeight();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setMobileHeight);
    };
  }, []);

  return (
    <div className={styles.xl}>
      <Header aboutRef={aboutRef} artistRef={artistRef} oracleRef={oracleRef} aiRef={aiRef} FAQRef={FAQRef} />
      <Galaxy />
      <div className={styles.aboutsection} ref={aboutRef}>
        <OracleContainer />
        <div className={styles.aboutsection} ref={aiRef}>
          <AIInterpretationContainer />
        </div>
      </div>
      <div className={styles.artistsection}>
        <img
          className={styles.gradient}
          alt=""
          src="/svg-gradient-white.svg"
        />
        <div className={styles.artistinnersection} ref={artistRef}>
          <CaiContainer />
          <div className={styles.caiGuoQiangWasFeaturedByParent}>
            <div className={styles.caiGuoQiangWas}>
              CAI’S WORK HAS BEEN FEATURED BY
            </div>
            <div className={styles.logoParent}>
              <a href="https://caiguoqiang.com/projects/projects-2008/i-want-to-believe-srgm/">
                <img
                  className={styles.guggenheimMuseumLogo1Icon}
                  alt=""
                  src="/guggenheim-museum-logo-1.svg"
                />
              </a>
              <a href="https://www.moma.org/collection/works/81348">
                <img
                  className={styles.netflixLogo}
                  alt=""
                  src="/museum-of-modern-art-logo-1.svg"
                />
              </a>
              <a href="https://caiguoqiang.com/projects/projects-2008/beijing-olympics/">
                <div className={styles.image1Wrapper}>
                  <img className={styles.image1Icon} alt="" src="/olympics.png" />
                </div>
              </a>
              <a href="https://www.netflix.com/title/80097472">
                <img
                  className={styles.netflixLogo}
                  alt=""
                  src="/netflix-2015-logo-1.svg"
                />
              </a>
              <a href="https://www.nytimes.com/2016/10/14/movies/sky-ladder-the-art-of-cai-guo-qiang-review.html">
                <img
                  className={styles.timesLogo}
                  alt=""
                  src="/NewYorkTimes.svg"
                />
              </a>
            </div>
          </div>
        </div>
        <img
          className={styles.gradientReversed}
          alt=""
          src="/svg-gradient-white.svg"
        />
      </div>
      <div ref={oracleRef}></div>
        <GifContainer />
        <QuestionContainer />
        <CreatorSection />
      <div className={styles.diagramsection}>
        <div className={styles.contentleft}>
          <div className={styles.howItWorks}>How It Works</div>
          <div className={styles.thinkOfEetContainer}>
            <p className={styles.thinkOfEet}>
            The EET oracle experience starts when you submit your question via a texting interface. Then, you'll be asked to tap on a set of rotating circles six times to allow randomness—a quality necessary to access the unseen world—to be tied to your fortune. After EET receives your question and randomness input, it generates the EET fortune on-chain and sends it to your wallet. The EET fortune is composed of an animated on-chain-generated GIF, along with a title and six verses. Once the EET fortune is in your wallet, the EET AI will be at hand to help you interpret your fortune.
            </p>
            <p className={styles.thinkOfEet} style={{ margin: "16px" }}>
              All components of the EET smart contract system are 100% on-chain and 100% decentralized. No off-chain dependencies. No admin privileges.
            </p>
          </div>
        </div>
        <div className={styles.diagramWrapper}>
          <img className={styles.diagram} alt="" src="/diagram.png" />
        </div>
      </div>
      <div className={styles.aboutsection} ref={FAQRef}>
        <FAQContainer />
      </div>
      <div className={styles.footer}>
        <div className={styles.twitterParent}>
          <a href="https://twitter.com/eetoracle" target="_blank">
            <img className={styles.twitterIcon} alt="" src="/Twitter.png" />
            </a>
          <a href="https://www.instagram.com/eetoracle/" target="_blank">
          <img className={styles.twitterIcon} alt="" src="/instagram.svg" />
          </a>
          <a href="https://discord.gg/eetoracle" target="_blank">
          <img className={styles.twitterIcon} alt="" src="/discord.svg" />
          </a>
          {/*
          <img className={styles.twitterIcon} alt="" src="/opensea.svg" />
        */}
        </div>
        <div className={styles.metadateMint}>
          <div className={styles.builtBy}>Built by</div>
          <a href="https://kanon.art">
            <div className={styles.kanon}>Kanon</div></a>
        </div>
      </div>
    </div>
  );
};

export default XL;