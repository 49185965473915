import { FunctionComponent } from "react";
import styles from "./QuestionContainer.module.css";

const QuestionContainer: FunctionComponent = () => {
  return (
    <div className={styles.questionsection}>
      <div className={styles.separatorWrapper}>
        <img className={styles.separator} alt="" src="separator-green.gif" />
      </div>
      <div className={styles.frameParent}>
        <div className={styles.contentWrapper}>
          <div className={styles.eetAnswersLifes}>
          GUIDANCE FOR NAVIGATING LIFE'S TOUGHEST QUESTIONS
          </div>
          <div className={styles.eetReadingsCan}>
          Sometimes life's toughest questions require more than logic and reason to answer. Taking cues from age-old oracle methods like the I Ching and kau chim, a fortunetelling practice popular in Cai’s hometown temples, EET fortunes offer insights that help you assess where you are, what you’re headed towards, and how you might be able to change your current path.
          </div>
          <div className={styles.gradientOverlay}></div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.innerFrameContainer}>
              <div className={styles.pastParent}>
                <div className={styles.past}>PAST</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    How has my upbringing shaped me today?
                  </div>
                  <div className={styles.whatShouldI}>
                    What decisions have I've made that changed the course of my life?
                  </div>
                  <div className={styles.whatShouldI}>
                    What has my time at McDonalds taught me about risk?
                  </div>
                  <div className={styles.whatShouldI}>
                    Have too many tendies made me lazy?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is buidl really the lesson I should draw from past bears?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>PRESENT</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    What is the most important aspect of my life I should focus on?
                  </div>
                  <div className={styles.whatShouldI}>
                    How can I best support my team members?
                  </div>
                  <div className={styles.whatShouldI}>
                    Am I long enough?
                  </div>
                  <div className={styles.whatShouldI}>
                    Why do I keep listening to my milady?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is Floki really the twitter CEO?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>FUTURE</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    Should I BTFD?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is hyperbitcoinzation on the horizon?
                  </div>
                  <div className={styles.whatShouldI}>
                    Will my product launch be a success?
                  </div>
                  <div className={styles.whatShouldI}>
                    Will my defi bags get hacked?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is an EO6102 on the cards for BTC?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>PAST</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    How has my upbringing shaped me today?
                  </div>
                  <div className={styles.whatShouldI}>
                    What decisions have I've made that changed the course of my life?
                  </div>
                  <div className={styles.whatShouldI}>
                    What has my time at McDonalds taught me about risk?
                  </div>
                  <div className={styles.whatShouldI}>
                    Have too many tendies made me lazy?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is buidl really the lesson I should draw from past bears?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>PRESENT</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    What is the most important aspect of my life I should focus on?
                  </div>
                  <div className={styles.whatShouldI}>
                    How can I best support my team members?
                  </div>
                  <div className={styles.whatShouldI}>
                    Am I long enough?
                  </div>
                  <div className={styles.whatShouldI}>
                    Why do I keep listening to my milady?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is Floki really the twitter CEO?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>FUTURE</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    Should I BTFD?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is hyperbitcoinzation on the horizon?
                  </div>
                  <div className={styles.whatShouldI}>
                    Will my product launch be a success?
                  </div>
                  <div className={styles.whatShouldI}>
                    Will my defi bags get hacked?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is an EO6102 on the cards for BTC?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>PAST</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    How has my upbringing shaped me today?
                  </div>
                  <div className={styles.whatShouldI}>
                    What decisions have I've made that changed the course of my life?
                  </div>
                  <div className={styles.whatShouldI}>
                    What has my time at McDonalds taught me about risk?
                  </div>
                  <div className={styles.whatShouldI}>
                    Have too many tendies made me lazy?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is buidl really the lesson I should draw from past bears?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>PRESENT</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    What is the most important aspect of my life I should focus on?
                  </div>
                  <div className={styles.whatShouldI}>
                    How can I best support my team members?
                  </div>
                  <div className={styles.whatShouldI}>
                    Am I long enough?
                  </div>
                  <div className={styles.whatShouldI}>
                    Why do I keep listening to my milady?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is Floki really the twitter CEO?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>FUTURE</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    Should I BTFD?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is hyperbitcoinzation on the horizon?
                  </div>
                  <div className={styles.whatShouldI}>
                    Will my product launch be a success?
                  </div>
                  <div className={styles.whatShouldI}>
                    Will my defi bags get hacked?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is an EO6102 on the cards for BTC?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>PAST</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    How has my upbringing shaped me today?
                  </div>
                  <div className={styles.whatShouldI}>
                    What decisions have I've made that changed the course of my life?
                  </div>
                  <div className={styles.whatShouldI}>
                    What has my time at McDonalds taught me about risk?
                  </div>
                  <div className={styles.whatShouldI}>
                    Have too many tendies made me lazy?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is buidl really the lesson I should draw from past bears?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>PRESENT</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    What is the most important aspect of my life I should focus on?
                  </div>
                  <div className={styles.whatShouldI}>
                    How can I best support my team members?
                  </div>
                  <div className={styles.whatShouldI}>
                    Am I long enough?
                  </div>
                  <div className={styles.whatShouldI}>
                    Why do I keep listening to my milady?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is Floki really the twitter CEO?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>FUTURE</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    Should I BTFD?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is hyperbitcoinzation on the horizon?
                  </div>
                  <div className={styles.whatShouldI}>
                    Will my product launch be a success?
                  </div>
                  <div className={styles.whatShouldI}>
                    Will my defi bags get hacked?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is an EO6102 on the cards for BTC?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>PAST</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    How has my upbringing shaped me today?
                  </div>
                  <div className={styles.whatShouldI}>
                    What decisions have I've made that changed the course of my life?
                  </div>
                  <div className={styles.whatShouldI}>
                    What has my time at McDonalds taught me about risk?
                  </div>
                  <div className={styles.whatShouldI}>
                    Have too many tendies made me lazy?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is buidl really the lesson I should draw from past bears?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>PRESENT</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    What is the most important aspect of my life I should focus on?
                  </div>
                  <div className={styles.whatShouldI}>
                    How can I best support my team members?
                  </div>
                  <div className={styles.whatShouldI}>
                    Am I long enough?
                  </div>
                  <div className={styles.whatShouldI}>
                    Why do I keep listening to my milady?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is Floki really the twitter CEO?
                  </div>
                </div>
              </div>
              <div className={styles.pastParent}>
                <div className={styles.past}>FUTURE</div>
                <div className={styles.whatShouldIKnowAboutMyPaParent}>
                  <div className={styles.whatShouldI}>
                    Should I BTFD?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is hyperbitcoinzation on the horizon?
                  </div>
                  <div className={styles.whatShouldI}>
                    Will my product launch be a success?
                  </div>
                  <div className={styles.whatShouldI}>
                    Will my defi bags get hacked?
                  </div>
                  <div className={styles.whatShouldI}>
                    Is an EO6102 on the cards for BTC?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameWrapper}>
            <a href="https://oracle.eet.et" target ="_blank" className={styles.consultOracleWrapper}>
              <div className={styles.consultOracle}>CONSULT ORACLE</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionContainer;
