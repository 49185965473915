import React, { useState, useEffect } from 'react';
import styles from './Tile.module.css';

const Tile: React.FC = () => {
  const [haloColor, setHaloColor] = useState('radial-gradient(49.7% 49.7% at 50% 50%, #2FC09F 0%, rgba(47, 192, 159, 0.36) 100%)');

  const changeHaloColor = () => {
    const randomChance = Math.random();

    if (randomChance <= 0.2) {
      // 20% chance of picking a different color
      setHaloColor('radial-gradient(49.7% 49.7% at 50% 50%, #735CE6 0%, rgba(115, 92, 230, 0.32) 100%');
    } else {
      // 80% chance of using the original color
      setHaloColor('radial-gradient(49.7% 49.7% at 50% 50%, #2FC09F 0%, rgba(47, 192, 159, 0.36) 100%)');
    }
  };

  return (
      <div className={styles.tile} onMouseEnter={changeHaloColor}>
        <div className={styles.point}></div>
        <div className={styles.diamond}></div>
        <div className={styles.halo} style={{ background: haloColor }}></div>
      </div>

  );
};

export default Tile;