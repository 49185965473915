import { FunctionComponent, useRef, useEffect, useState } from "react";
import styles from "./CaiContainer.module.css";

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function updateIsMobile() {
      setIsMobile(window.innerWidth < 980);
    }

    updateIsMobile();
    window.addEventListener("resize", updateIsMobile);
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  return isMobile;
}

const CaiContainer: FunctionComponent = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isMobile) return; // Skip the logic if not on mobile
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const target = entry.target as HTMLVideoElement;

          // Calculate the size based on intersectionRatio
          const newSize = 70 + (entry.intersectionRatio * 100) * 0.4;

          target.style.maxWidth = `${newSize}%`;
        });
      },
      {
        threshold: Array.from({ length: 21 }, (_, i) => i * 0.05),
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [isMobile]);

  return (
    <div className={styles.caisection}>
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          {/* Add both webm and mp4 sources to the video element */}
          <video className={styles.frameChild} ref={videoRef} loop autoPlay playsInline muted>
            <source src="/Ignition.webm" type="video/webm" />
            <source src="/Ignition.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p className={styles.caption}>Sky Ladder, 2015, realized off Huiyu Island, Quanzhou, June 15, 4:45am</p>
        </div>
        <div className={styles.contentright}>
          <img className={styles.separator} alt="" src="/separator-purple.gif" />
          <div className={styles.headerContainer}>
            <p className={styles.imaginedBy}>A DIALOGUE WITH THE UNSEEN WORLD</p>
          </div>
          <div className={styles.chineseBornArtistCaiContainer}>
            <p
              className={styles.imaginedBy}
            >{`For a long time the core of contemporary artist Cai Guo-Qiang's work has been to express the unseen world through visible means, to encourage a dialogue with it and to leverage it as a source of guidance in times of uncertainty. Emblematic of Cai's dialogue with the unseen world is Sky Ladder, one of his most well-known explosion projects and the subject of a 2016 Netflix documentary by the same name.`}</p>
            <p className={styles.imaginedBy}>&nbsp;</p>
            <p className={styles.imaginedBy}>
            Cai’s artworks have been showcased by institutions around the world including the Museum of Modern Art, the Guggenheim Museum, the Metropolitan Museum of Art, Museo del Prado, and many more. EET is Cai’s latest experimentation with blockchain as a creative tool. By introducing an on-chain oracle, Cai attempts to bridge the physical and the virtual while pondering the question: How can art heal the world?
            </p>
          </div>
          <button className={styles.learnMoreWrapper}>
            <a className={styles.learnMore} href="#creator">
              LEARN MORE
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CaiContainer;
