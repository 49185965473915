import { FunctionComponent, useState } from "react";
import styles from "./CreatorSection.module.css";

const CreatorSection: FunctionComponent = () => {
  const [showFullContent, setShowFullContent] = useState(false);
  const [showFullContent1, setShowFullContent1] = useState(false);

  return (
    <div className={styles.creatorsection}>
      <img
        className={styles.gradientBlackGrey}
        alt=""
        src="/svg-gradient-white.svg"
      />
      <div id="creator">
        <div className={styles.contentwrapper}>
          <div className={styles.caisection}>
            <div className={styles.headline}>
              <p className={styles.about}>About</p>
              <p className={styles.about}>Cai Guo-Qiang</p>
            </div>
            <img className={styles.caiAlienIcon} alt="" src="/caialien@2x.png" />
            <p className={styles.caption}>Photo by Kenryou Gu, courtesy Cai Studio</p>
            <div className={styles.quote}>
              “In this era, we question the concept of time more strongly than
              ever. What is the essence of time? What is the truth of time?
            </div>
            <div className={styles.copy}>
              <p className={styles.about}>
                Cai Guo-Qiang was born in Quanzhou, Fujian in 1957. In the early 1980s, he was trained in stage design at the Shanghai Theatre Academy. From the end of 1986 to 1995, he resided in Japan; he has lived and worked in New York City since. Grounded in the conceptual foundations of eastern philosophy and contemporary social issues, his artworks span multiple artistic mediums including painting, installation, video, and performance art. <br></br><br></br>His often site-specific artworks adapt to local conditions, interpreting and responding to the local culture and history as well as establishing a dialogue between viewers and the larger universe around them. His famed explosion art and installations are imbued with a force that transcends the two-dimensional plane to oscillate freely between society and nature.
              </p>
              {showFullContent ? (
                <>
                  <p className={styles.about}>Cai has received many high-profile awards, including the Golden Lion at the Venice Biennale in 1999 and the Hiroshima Art Prize in 2007. In 2012, he was honored as a Laureate for the prestigious Praemium Imperiale, known as the “Nobel Prize of the Art World,” in the painting category. That same year, he was awarded the first U.S. Department of State Medal of Arts. Cai also served as a member of the core creative team and as the Director of Visual and Special Effects for the opening and closing ceremonies of the 2008 Summer Olympic Games in Beijing. Recently, he also served as the Director of Visual Effects and Fireworks for the opening and closing ceremonies of the 2022 Winter Olympic Games in Beijing.</p>
                  <p className={styles.about}>Cai’s artworks have been showcased by institutions around the world including the Museum of Modern Art, the Guggenheim Museum, the Metropolitan Museum of Art, Museo del Prado, and many more. With EET, Cai’s vision is to heal the world with art at scale by inviting us on a thought-provoking journey, inviting introspection and self-awareness about our current paths.</p>
                  <div className={styles.socialwrapper}>
                    <div className={styles.subline}>
                      To learn more about Cai and its projects
                    </div>
                    <a href="https://www.caiguoqiang.com">
                      <div className={styles.website}>caiguoqiang.com</div>
                    </a>
                    <div className={styles.socials}>
                      <a href="https://twitter.com/caiguoqiang">
                        <div className={styles.website}>Twitter</div>
                      </a>
                      <a href="https://www.instagram.com/caistudio">
                        <div className={styles.website}>Instagram</div>
                      </a>
                      <a href="https://www.facebook.com/cgqstudio">
                        <div className={styles.website}>Facebook</div>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <button
                  className={styles.readMoreButton}
                  onClick={() => setShowFullContent(true)}
                >
                  Read More
                </button>
              )}
            </div>
            <div className={styles.kanonsection}>
              <div className={styles.headline1}>About kanon</div>
              <div className={styles.copy1}>
                <p className={styles.about}>
                  Kanon is a pseudonymous collective of art, design, and cryptonative technology professionals dedicated to pushing the boundaries of crypto-enabled
                  artistic expression. Constantly innovating at all stages of the digital creative
                  process, from visual to open-source protocol design, Kanon is supporting a
                  novel model in which creative expression, structural experimentation, and
                  financial return are mutually reinforcing. <br></br><br></br>In March 2021, Kanon launched the K21 Collection as its inaugural product.
                  K21 is a closed-end art vault of 21 unique NFT artworks by a diverse roster
                  of influential and pioneering contemporary, digital, and cryptonative
                  artists.
                </p>
                {showFullContent1 ? (
                  <>
                    <p className={styles.about}> Including the latest work acquired from Cai Guo-Qiang, the K21
                      Collection has completed the acquisition of 20 artworks from artists such
                      as Paul Chan, China Tracy (Cao Fei), David OReilly, Liam Gillick, Rirkrit
                      Tiravanija and 15 others.</p>
                    <p className={styles.about}>Later in 2021, Kanon brought magic to the blockchain with the NFT art
                      project DAEMONICA, a visual and mathematical system of onchain occult
                      operations, in collaboration with one of the artists of the K21 Collection who
                      wishes to remain anonymous. Provenance for the artworks in the K21 Collection is secured through KSPEC, an open-source protocol developed by Kanon
                      that provides fully decentralized, and fully onchain, institutional-grade
                      provenance and preservation for digital artworks. In April 2022, Kanon
                      released erc721Q, a powerful new protocol for borrowing and lending NFTs
                      that allows anyone to transfer an NFT to any wallet at any time, without the
                      owner losing control of the NFT.</p>
                    <div className={styles.socialwrapper}>
                      <div className={styles.subline}>
                        To learn more about Kanon and its projects
                      </div>
                      <div className={styles.socials}>
                        <a href="https://k21.kanon.art/">
                          <div className={styles.website}>k21.kanon.art</div>
                        </a>
                        <a href="https://twitter.com/kanondotart">
                          <div className={styles.website}>Twitter</div>
                        </a>
                        <a href="https://www.instagram.com/kanondotart">
                          <div className={styles.website}>Instagram</div>
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <button
                    className={styles.readMoreButton}
                    onClick={() => setShowFullContent1(true)}
                  >
                    Read More
                  </button>
                )}
              </div>
            </div>
          </div>
          <img
            className={styles.gradientBlackGreyReversed}
            alt=""
            src="/svg-gradient-white.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default CreatorSection;